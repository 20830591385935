.App {
    display: flex;
    flex-direction: row;
    width: 100%;
}

/* .nav {
 max-width: 340px;
} */

.main {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .main {
        margin-left: 5px;
    }
  }